.footer {
  margin-top: 20px;
  .footer_wrapper {
    text-align: center;
    padding: 10px 0;
    .footer_info {
      text-align: center;
    }
  }
}
