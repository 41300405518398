.breadcrumb {
  margin: 1rem 0;
  .breadcrumb_warapper {
    .breadcrumb_content {
      height: 40px;
      width: 100%;
      background-color: #191d1b;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      &::before {
        border-radius: 8px;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 60px;
        z-index: 2;
        background: linear-gradient(90deg, #191d1b, #191d1b00);
        left: 0;
      }
      &::after {
        border-radius: 8px;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 60px;
        z-index: 2;
        background: linear-gradient(270deg, #191d1b, #191d1b00);
        right: 0;
      }
      .breadcrumb_unlist {
        // display: inline-block;
        // padding: 0;
        // margin: 0;
        // list-style-type: none;
        // overflow: hidden;
        // position: absolute;
        // white-space: nowrap;
        // left: 100%;
        // width: min-content;
        //animation: scroll-horizontal 76.5s linear infinite;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 8px;
        min-width: 180px;
        animation: scroll-horizontal linear infinite;
        .breadcrumb_list_item {
          display: inline-block;
          margin: 0 8px;
          .breadcrumb_texts {
            color: aliceblue;
            font-size: 0.9rem;
            .breadcrumb_caret_ic {
              fill: #00a300;
              height: 20px;
              width: 15px;
              margin-bottom: -6px;
            }
          }
        }
      }
      // .breadcrumb_unlist_secondary {
      //   animation: scroll-horizontal linear infinite;
      //   animation-delay: calc(76.5s / 2);
      // }
    }
  }
}

@keyframes scroll-horizontal {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
