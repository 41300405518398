.breadcrumb {
  margin: 1rem 0;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content {
  height: 40px;
  width: 100%;
  background-color: #191d1b;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content::before {
  border-radius: 8px;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 60px;
  z-index: 2;
  background: linear-gradient(90deg, #191d1b, rgba(25, 29, 27, 0));
  left: 0;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content::after {
  border-radius: 8px;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 60px;
  z-index: 2;
  background: linear-gradient(270deg, #191d1b, rgba(25, 29, 27, 0));
  right: 0;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content .breadcrumb_unlist {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 8px;
  min-width: 180px;
  animation: scroll-horizontal linear infinite;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content .breadcrumb_unlist .breadcrumb_list_item {
  display: inline-block;
  margin: 0 8px;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content .breadcrumb_unlist .breadcrumb_list_item .breadcrumb_texts {
  color: aliceblue;
  font-size: 0.9rem;
}
.breadcrumb .breadcrumb_warapper .breadcrumb_content .breadcrumb_unlist .breadcrumb_list_item .breadcrumb_texts .breadcrumb_caret_ic {
  fill: #00a300;
  height: 20px;
  width: 15px;
  margin-bottom: -6px;
}

@keyframes scroll-horizontal {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-100%);
  }
}/*# sourceMappingURL=breadcrumb.css.map */